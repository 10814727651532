<template>
  <div>
    <div v-show="!showLoading" class="online-container w-500 rounded-[10px] bg-white transition-all">
      <el-tabs v-model="activeTab" tab-position="top" style="width: 500px">
        <el-tab-pane name="current">
          <template #label>
            <i class="iconfont icon-renshu mr-10"></i><span>{{ $t('实时在线人数') }}</span>
          </template>
          <div class="px-20">
            <div class="mb-20 flex justify-between">
              <div class="w-200">
                <div class="mb-10 text-26 font-[500]">{{ props.onlinesummery.count }} 人</div>
                <div class="text-gray">( {{ $t('更新时间') }}：{{ dayjs(props.onlinesummery.updateTime * 1000).format('MM-DD HH:mm') }} )</div>
              </div>
              <div class="w-200">
                <ClientOnly>
                  <indexChart
                    :id="`chart-${Math.random() * 10000}`"
                    :data="props.onlinesummery.history"
                    :x-key="0"
                    :y-key="1"
                    :class-name="'online-chart'"
                    :last-day-end="true"
                  ></indexChart>
                </ClientOnly>
              </div>
            </div>
            <div class="flex justify-between rounded-[10px] bg-blue-50 py-10">
              <div class="flex min-w-150 flex-col items-center">
                <div class="mb-5 text-gray-light">{{ $t('昨日同时段') }}</div>
                <div class="mb-5 text-20">{{ props.onlinesummery.yesterdayHour }}</div>
                <div :class="textColor(props.onlinesummery.yesterdayHourRatio)">
                  {{ needPlus(props.onlinesummery.yesterdayHourRatio) }}{{ props.onlinesummery.yesterdayHourRatio }}%
                </div>
              </div>
              <div class="vertical-divider"></div>
              <div class="flex min-w-150 flex-col items-center">
                <div class="mb-5 text-gray-light">{{ $t('上周同时段') }}</div>
                <div class="mb-5 text-20">{{ props.onlinesummery.lastWeekHour }}</div>
                <div :class="textColor(props.onlinesummery.lastWeekHourRatio)">
                  {{ needPlus(props.onlinesummery.lastWeekHourRatio) }}{{ props.onlinesummery.lastWeekHourRatio }}%
                </div>
              </div>
              <div class="vertical-divider"></div>
              <div class="flex min-w-150 flex-col items-center">
                <div class="mb-5 text-gray-light">{{ $t('本月平均') }}</div>
                <div class="mb-5 text-20">{{ props.onlinesummery.monthAvg }}</div>
                <div :class="textColor(props.onlinesummery.lastMonthAvgRatio)">
                  {{ needPlus(props.onlinesummery.lastMonthAvgRatio) }}{{ props.onlinesummery.lastMonthAvgRatio }}%
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('峰值在线人数')" name="highest">
          <template #label>
            <i class="iconfont icon-huo mr-10"></i><span>{{ $t('峰值在线人数') }}</span>
          </template>
          <div class="px-20">
            <div class="mb-20 flex justify-between">
              <div class="w-200">
                <div class="mb-10 text-26 font-[500]">{{ props.onlinesummery.todayMax }} 人</div>
                <div class="text-gray">( {{ $t('更新时间') }}：{{ dayjs(props.onlinesummery.updateTime * 1000).format('MM-DD HH:mm') }} )</div>
              </div>
              <div>
                <ClientOnly>
                  <indexChart
                    :id="`chart-${Math.random() * 10000}`"
                    :data="props.onlinesummery.history"
                    :x-key="0"
                    :y-key="1"
                    :class-name="'online-chart'"
                    :last-day-end="true"
                  ></indexChart>
                </ClientOnly>
              </div>
            </div>
            <div class="flex justify-between rounded-[10px] bg-blue-50 py-10">
              <div class="flex min-w-150 flex-col items-center">
                <div class="mb-5 text-gray-light">{{ $t('昨日峰值') }}</div>
                <div class="mb-5 text-20">{{ props.onlinesummery.yesterdayMax }}</div>
                <div :class="textColor(props.onlinesummery.yesterdayMaxRatio)">
                  {{ needPlus(props.onlinesummery.yesterdayMaxRatio) }}{{ props.onlinesummery.yesterdayMaxRatio }}%
                </div>
              </div>
              <div class="vertical-divider"></div>
              <div class="flex min-w-150 flex-col items-center">
                <div class="mb-5 text-gray-light">{{ $t('上周峰值') }}</div>
                <div class="mb-5 text-20">{{ props.onlinesummery.lastWeekMax }}</div>
                <div :class="textColor(props.onlinesummery.lastWeekMaxRatio)">{{ needPlus(props.onlinesummery.lastWeekMaxRatio) }}{{ props.onlinesummery.lastWeekMaxRatio }}%</div>
              </div>
              <div class="vertical-divider"></div>
              <div class="flex min-w-150 flex-col items-center">
                <div class="mb-5 text-gray-light">{{ $t('本月峰值') }}</div>
                <div class="mb-5 text-20">{{ props.onlinesummery.monthMax }}</div>
                <div :class="textColor(props.onlinesummery.lastMonthMaxRatio)">
                  {{ needPlus(props.onlinesummery.lastMonthMaxRatio) }}{{ props.onlinesummery.lastMonthMaxRatio }}%
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div v-show="showOnlineChart" class="online-chart-container px-20">
        <div class="online-chart-dom h-197 w-460"></div>
      </div>
      <div class="mt-20 cursor-pointer pb-10 text-center" :class="[showOnlineChart ? 'text-gray' : 'text-blue']" @click="showOnlineChart = !showOnlineChart">
        {{ showOnlineChart ? $t('收起在线人数走势') : $t('展开在线人数走势') }}
        <i class="iconfont icon-xiangxiahuadong text-8" :class="[showOnlineChart ? 'icon-a-lujing2' : 'icon-xiangxiahuadong']"></i>
      </div>
    </div>
    <el-skeleton v-show="showLoading" :loading="showLoading" style="width: 500px">
      <template #template>
        <div class="flex w-full justify-center">
          <el-skeleton-item variant="ract" style="width: 500px; height: 320px" />
        </div>
      </template>
    </el-skeleton>
  </div>
</template>

<script setup>
import indexChart from './indexChart.vue'
import * as echarts from 'echarts/core'
import { GridComponent, VisualMapComponent, TooltipComponent, DataZoomComponent } from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'
import { newIndexOnlineTrend } from '~/api/steam'
import dayjs from 'dayjs'
const activeTab = ref('current')
const showOnlineChart = ref(false)

const props = defineProps({
  onlinesummery: {
    type: Object,
    required: true
  }
})

const chartList = ref([])
const showLoading = ref(true)

onMounted(() => {
  let dateList = []
  let valueList = []
  let maxList = []
  newIndexOnlineTrend().then(res => {
    chartList.value = res.data
    dateList = chartList.value.map(item => {
      return item[0]
    })
    valueList = chartList.value.map(item => {
      return item[1]
    })
    maxList = chartList.value.map(item => {
      return item[2]
    })
    echartInit(dateList, valueList, maxList)
  })
  showLoading.value = false
})

const echartInit = (dateList, valueList, maxList) => {
  echarts.use([GridComponent, VisualMapComponent, LineChart, CanvasRenderer, UniversalTransition, TooltipComponent, DataZoomComponent, SVGRenderer])
  const dom = document.querySelector('.online-chart-dom')
  const myChart = echarts.init(dom, null, {
    renderer: 'svg'
  })
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    dataZoom: [
      {
        show: false,
        realtime: true,
        start: 50,
        end: 100,
        xAxisIndex: [0, 1]
      },
      {
        type: 'inside',
        realtime: true,
        start: 95,
        end: 100,
        xAxisIndex: [0, 1]
      }
    ],
    xAxis: [
      {
        type: 'category',

        boundaryGap: false,
        data: dateList
      }
    ],
    yAxis: [
      {
        type: 'value',
        position: 'right',
        min: 'dataMin'
      }
    ],
    series: [
      {
        name: '峰值',
        type: 'line',
        // stack: '总量',
        showSymbol: false,
        smooth: 0.3,
        lineStyle: {
          width: 1
        },
        // areaStyle: {},
        data: maxList
      },
      {
        name: '平均值',
        type: 'line',
        // stack: '总量',
        showSymbol: false,
        smooth: 0.3,
        lineStyle: {
          width: 1
        },
        // areaStyle: {},
        data: valueList
      }
    ]
  }
  myChart.setOption(option)
}

const needPlus = num => {
  return num > 0 ? '+' : ''
}

const textColor = num => {
  return num > 0 ? 'text-red' : num < 0 ? 'text-green' : 'text-gray'
}
</script>

<style lang="scss">
.online-container {
  .el-tabs__nav-scroll {
    padding-left: 20px;
    .el-tabs__item {
      height: 62px;
      font-size: 16px;
    }
  }
  .shouqi {
    &::before {
      transform: rotate(90deg);
    }
  }
  .zhankai {
    &::before {
      transform: rotate(-90deg);
    }
  }
}
</style>
